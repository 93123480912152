


































































































import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType, AuditFlowScope, LecturerDto, TrainApplyDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {AuditBodyDto} from "@/utils/customDto";

import AuditBlock, { AuditApi } from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "LecturerDetail",
  components: {
    AuditBlock,
    AttachmentsView
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Deleted":
          return "已删除";
      }
    },
  },
})
export default class LecturerDetail extends Vue {


  dataId?: number;
  detail: TrainApplyDto = {
    train: {},
    user:{},
    userExtend:{},
    volunteer:{}
  };
  baseURL: any;
  loading = true;



  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.trainApply.get({ id: this.dataId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };

    });
  }

  getTime(item:any){
    return moment(item).format("HH:mm");
  }

  // 下载附件
  downLoad(item: any) {
    window.location.href = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
  }


  get auditApi(){
    return {
      canAudit: api.trainApply.canAudit,
      userAudit: api.trainApply.audit
    } as AuditApi
  }
}
